/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import 'regenerator-runtime/runtime';
import { isNotEmpty } from '../../Utils/utils';
import { postier } from '../Videos/list/Postier';

class Postier {
    async writeCount(
        token: string,
        finalCount: any,
        filename: string,
        ip: string,
        fromSaved: boolean,
        setVideos,
        setIsSaved,
    ) {
        await axios
            .post(
                '/api-v0/count',
                { count: finalCount, filename },
                { params: { ip }, headers: { Authorization: `Bearer ${token}` } },
            )
            .then(async () => {
                setIsSaved(true);
                fromSaved && alert('Comptage sauvegardé avec succès');
                await postier.getVideosList(token, setVideos, ip);
            })
            .catch(() => alert('Erreur, la sauvegarde a échouée'));
    }

    getCount(token: string, filename: string, setDataCount, setCategories, ip: string, setTags) {
        axios
            .get('/api-v0/count', { params: { filename, ip }, headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                if (!isNotEmpty(res.data)) {
                    setDataCount({ data: [], totalCount: 0, date: null, end: false });
                    setTags([]);
                } else {
                    const { data, totalCount, date, end } = res.data[0];
                    setDataCount({ data, totalCount, date, end });
                    const tags = data.map((data) => data.frame.tag);
                    setTags(tags);
                    if (res.data[0].data.length !== 0) {
                        let categories = data.map((data) => data.category);
                        categories = [...new Set(categories)];
                        setCategories(categories);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

export const countPostier = new Postier();
