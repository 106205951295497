
import * as React from 'react'

import "./header.scss"
import logo from "../../../assets/logo-Cliris.svg"

export function Header() {
    return (
        <div className="main-header">
            <img src={logo} width="200" height="125" alt="logo"/>
            <h1>Audit MasterTool</h1>
            <div></div>
        </div>
    )
}
