import * as React from 'react';
import { useEffect, useState } from 'react';
import { Video } from '../Components/Videos/IVideos';

export const VideoContext = React.createContext(null);

export function AppProvider({ children }) {
    const [token, setToken] = useState('');
    const [userId, setUserId] = useState<any>(0);
    const [appId, setAppId] = useState(2);
    const [role, setRole] = useState(localStorage.getItem('role') || 'Viewer');
    const [page, setPage] = useState(localStorage.getItem('page') || 'video');
    const [loading, setLoading] = useState(false);
    const [filename, setFilename] = useState<string>('');
    const [videoFps, setVideoFps] = useState<number>(0);
    const [dataCount, setDataCount] = useState({ data: [], totalCount: 0, date: null, end: false });
    const [url, setUrl] = useState<string>('');
    const [finalCount, setFinalCount] = useState({ data: [], totalCount: 0, date: null, end: false });
    const [frameCaptured, setFrameCaptured] = useState<number>(0);
    const [categories, setCategories] = useState([]);
    const [videoSelected, setVideoSelected] = useState<boolean>(false);
    const [frameNumber, setFrameNumber] = useState<number>(0);
    const [clicked, setClicked] = useState<boolean>(false);
    const [boxIp, setBoxIp] = useState<string>(localStorage.getItem('boxIp') || '');
    const [cptIn, setCptIn] = useState(0);
    const [cptOut, setCptOut] = useState(0);
    const [countFiltered, setCountFiltered] = useState({ data: [], totalCount: 0, date: null });
    const [videoNameSelected, setVideoNameSelected] = useState<string>('');
    const [saved, setSaved] = useState<number>(10);
    const [dataConfig, setDataConfig] = useState({ data: [], savedNumber: 10 });
    const [configCategories, setConfigCategories] = useState([]);
    const [keyPressedStatus, setKeyPressedStatus] = useState([]);
    const [tags, setTags] = useState<Array<number>>([]);
    const [fromRemove, setFromRemove] = useState<boolean>(false);
    const [scrollable, setScrollable] = useState<boolean>(true);
    const [glossary, setGlossary] = useState<boolean>(false);
    const [videosCount, setVideosCount] = useState([]);
    const [videos, setVideos] = useState<Video[]>([]);
    const [expirationTime, setExpirationTime] = useState<number>(43200000);
    const [showImg, setShowImg] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);

    useEffect(() => {
        setToken(getWithExpiry('token'));
        setUserId(getWithExpiry('userid'));
        setRole(getWithExpiry('role'));
    }, []);

    useEffect(() => {
        localStorage.setItem('page', page);
    }, [page]);

    const deconnexion = () => {
        setToken(undefined);
        localStorage.removeItem('token');
        setUserId(0);
        localStorage.removeItem('userid');
        localStorage.removeItem('connect');
        localStorage.removeItem('role');
    };

    const getWithExpiry = (key: string) => {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return null;
        }

        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    };

    return (
        <VideoContext.Provider
            value={{
                token,
                setToken,
                userId,
                setUserId,
                appId,
                setAppId,
                deconnexion,
                page,
                setPage,
                loading,
                setLoading,
                videoFps,
                setVideoFps,
                filename,
                setFilename,
                dataCount,
                setDataCount,
                url,
                setUrl,
                finalCount,
                setFinalCount,
                frameCaptured,
                setFrameCaptured,
                categories,
                setCategories,
                videoSelected,
                setVideoSelected,
                frameNumber,
                setFrameNumber,
                clicked,
                setClicked,
                boxIp,
                setBoxIp,
                cptIn,
                setCptIn,
                cptOut,
                setCptOut,
                countFiltered,
                setCountFiltered,
                videoNameSelected,
                setVideoNameSelected,
                role,
                setRole,
                saved,
                setSaved,
                dataConfig,
                setDataConfig,
                configCategories,
                setConfigCategories,
                keyPressedStatus,
                setKeyPressedStatus,
                tags,
                setTags,
                fromRemove,
                setFromRemove,
                videos,
                setVideos,
                scrollable,
                setScrollable,
                glossary,
                setGlossary,
                videosCount,
                setVideosCount,
                expirationTime,
                setExpirationTime,
                showImg,
                setShowImg,
                isSaved,
                setIsSaved,
            }}
        >
            {children}
        </VideoContext.Provider>
    );
}
