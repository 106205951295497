import axios from 'axios';
import { SetStateAction } from 'react';
import 'regenerator-runtime/runtime';
import { isNotEmpty } from '../../Utils/utils';

class Postier {
    async writeConfig(token: string, config: any, ip: string) {
        axios
            .post('/api-v0/config', { config }, { params: { ip }, headers: { Authorization: `Bearer ${token}` } })
            .then(() => alert('Configuration sauvegardée avec succès'))
            .catch((err) => console.log(err));
    }

    getConfig(token: string, setDataConfig, setConfigCategories, ip: string, setSaved: SetStateAction<any>) {
        axios
            .get('/api-v0/config', { params: { ip }, headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                if (isNotEmpty(res.data)) {
                    setDataConfig({ data: res.data.data, savedNumber: res.data.savedNumber });
                    const categories = res.data.data.map((data) => data.category);
                    setConfigCategories(categories);
                    setSaved(parseInt(res.data.savedNumber));
                } else {
                    setDataConfig({ data: [], savedNumber: 10 });
                    setConfigCategories([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

export const configPostier = new Postier();
