import * as React from 'react'
import {postier} from './Postier'
import {VideoContext} from '../../Context/VideoContext'
import logo from '../../../assets/logo-Cliris.svg'

import "./login.scss"
import { useContext, useState } from 'react'

export function Login() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const {setToken,setUserId, setRole} = useContext(VideoContext)

    const connexion = (e)=>{
        e.preventDefault()
        postier.getToken(username,password,setToken,setUserId, setRole)
    }

    return (
        <div className="container-login">
            <div className="center">
                <img height="100" width="380" alt="logo cliris" src={logo} />
            </div>
            <div className="center">
                <form onSubmit={connexion}>
                    <h1>Audit MasterTool</h1>
                    <input type="text" onChange={e=>setUsername(e.target.value)} placeholder="Nom d'utilisateur"/>
                    <input type="password" onChange={e=>setPassword(e.target.value)} placeholder="Mot de passe"/>
                    <button>Se connecter</button>
                </form>
            </div>
        </div>
    )
}
