export const isNotEmpty = (array: Array<any>) => {
    return array.length !== 0;
};

export const countFilteredUtils = (array, frameCaptured: number, category: string, type: string) => {
    return array.filter((cnt) => cnt.frame.number === frameCaptured && cnt.category === category && cnt.type === type);
};

export const removeDuplicates = (array) => {
    return [...array]
        .reverse()
        .filter(
            (item, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.type === item.type && t.category === item.category && t.frame.number === item.frame.number,
                ),
        );
};

export const fileToJson = (file: string) => {
    return file.replace(/\.[^.]+$/, '.json');
};

export const fileToMp4 = (file: string) => {
    return file.replace(/\.[^.]+$/, '.mp4');
};
