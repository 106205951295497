import * as React from 'react';
import { useContext } from 'react';
import { VideoContext } from '../../Context/VideoContext';
import './glossary.scss';

export default function Glossary() {
    const { dataConfig, setGlossary } = useContext(VideoContext);

    return (
        <div className="glossaire">
            <div className="line-manager" style={{ backgroundColor: 'white', marginBottom: '1em' }}>
                <h1 style={{ textAlign: 'center' }}>Glossaire</h1>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <button onClick={() => setGlossary(false)}>Fermer</button>
                </div>
            </div>
            <div className="tuto">
                <div className="element">
                    <label>
                        <kbd>Spacebar</kbd> : Play
                    </label>
                    <label>
                        <kbd>&rarr;</kbd> : Speed up
                    </label>
                    <label>
                        <kbd>&larr;</kbd> : Speed down{' '}
                    </label>
                </div>

                {dataConfig.data.length !== 0 &&
                    dataConfig.data.map((dc, index: number) => {
                        return (
                            <div key={index} className="element">
                                Category : {dc.category}
                                <label>
                                    <kbd>{dc.shortcutIn}</kbd> : In
                                </label>
                                <label>
                                    <kbd>{dc.shortcutOut}</kbd> : Out
                                </label>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
