import * as React from 'react';
import { useContext, useState } from 'react';

import { VideoContext } from '../../Context/VideoContext';
import { isNotEmpty } from '../../Utils/utils';

import './style.scss';

export function PageSelector() {
    const { setGlossary, setPage, page, deconnexion, role, setBoxIp, countFiltered, isSaved } = useContext(VideoContext);

    const [ip, setIp] = useState<string>('');

    const handleIp = (ip: string) => {
        if (ip !== '') {
            setBoxIp(ip);
            localStorage.setItem('boxIp', ip);
        }
    };

    const handleGoToAdmin = () => {
        let r = true;
        if (isNotEmpty(countFiltered.data) && !isSaved) {
            r = confirm(
                "Voulez vous vraiment aller sur la page d'administration ? Vos comptages non enregistrés seront perdus",
            );
        }

        if (r) {
            setPage('admin');
            window.location.reload(); // Hack dégueulasse pour update les comptages quand on reviendra sur la page vidéo
        }
    };

    return (
        <div className="page-selector">
            <div className="buttonList">
                {role === 'Admin' && (
                    <>
                        <button
                            className={page === 'video' ? 'selected' : ''}
                            onClick={() => {
                                setPage('video');
                            }}
                        >
                            Page vidéo
                        </button>
                        <button className={page === 'admin' ? 'selected' : ''} onClick={handleGoToAdmin}>
                            Paramètres vidéo
                        </button>
                    </>
                )}
                <button onClick={deconnexion}>Déconnexion</button>
            </div>
            <div className="buttonList">
                <label className="box-address">Adresse box</label>
                <input
                    className="box-ip"
                    defaultValue={localStorage.getItem('boxIp')}
                    placeholder="Box IP"
                    type="text"
                    onChange={(e) => setIp(e.target.value)}
                />
                <button onClick={() => handleIp(ip)}>Connecter</button>
                <button onClick={() => setGlossary(true)}>Aide</button>
            </div>
        </div>
    );
}
