import * as React from 'react';
import ReactPlayer from 'react-player';
import { useState, useRef, useContext, useEffect } from 'react';
import './style.scss';
import { VideoContext } from '../../Context/VideoContext';
import Videos from './list/Videos';
import { Counter } from '../Count';
import { fileToJson, isNotEmpty } from '../../Utils/utils';
import play from '../../../assets/pause.svg';
import pause from '../../../assets/play-button.svg';
import fastForward from '../../../assets/fast_forward_icon.svg';
import fastRewind from '../../../assets/fast-rewind.svg';
import stopButton from '../../../assets/stop-button.svg';
import { countPostier } from '../Count/Postier';
import Glossary from '../Glossary';
import { postier } from './list/Postier';

const SCROLL_MOVE = 0.01;

export default function Video() {
    const {
        glossary,
        scrollable,
        setScrollable,
        setCptIn,
        setCptOut,
        setFilename,
        setVideoNameSelected,
        token,
        setUrl,
        setDataCount,
        setCategories,
        tags,
        setTags,
        finalCount,
        countFiltered,
        dataCount,
        videoFps,
        url,
        setFrameCaptured,
        frameNumber,
        setFrameNumber,
        clicked,
        setClicked,
        boxIp,
        setVideosCount,
        setVideoSelected,
        setVideos,
        setShowImg,
        setIsSaved,
    } = useContext(VideoContext);

    const [playbackRate, setplaybackRate] = useState<number>(1.0);
    const [seeking, setSeeking] = useState<boolean>(false);
    const [playingState, setPlayingState] = useState<any>({});
    const player = useRef<any>({ seekTo: (amount: number) => amount });
    const [playing, setPlaying] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(0);
    const [endedVideo, setEndedVideo] = useState<boolean>(false);
    const height = 16;

    useEffect(() => {
        setEndedVideo(true);
    }, [endedVideo]);

    useEffect(() => {
        setFrameCaptured(frameNumber);
    }, [frameNumber]);

    useEffect(() => {
        handleGoToFrame(frameNumber);
        setClicked(false);
    }, [clicked]);

    useEffect(() => {
        const cb = (e: { keyCode: number }) => {
            switch (e.keyCode) {
                case 32:
                    playing ? setPlaying(false) : setPlaying(true);
                    break;
                case 39:
                    handleSpeedUp();
                    break;
                case 37:
                    handleSpeedDown();
                    break;
            }
        };
        document.addEventListener('keydown', cb);
        return () => {
            document.removeEventListener('keydown', cb);
        };
    }, [playing, playbackRate]);

    useEffect(() => {
        const element: any = document.getElementsByClassName('progress');
        setWidth(element[0].offsetWidth);
    }, []);

    useEffect(() => {
        if (
            !(Object.keys(playingState).length === 0 && playingState.constructor === Object) &&
            isNotEmpty(countFiltered.data)
        ) {
            handleTags(countFiltered);
        } else if (
            !(Object.keys(playingState).length === 0 && playingState.constructor === Object) &&
            isNotEmpty(dataCount.data)
        ) {
            handleTags(dataCount);
        } else if (!(Object.keys(playingState).length === 0 && playingState.constructor === Object)) {
            setTags([]);
        }
    }, [finalCount, countFiltered, dataCount]);

    useEffect(() => {
        document.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleWheel);
        };
    }, [scrollable]);

    useEffect(() => {
        const videoDiv = document.getElementById('section-video');

        const mouseover = (e) => {
            e.preventDefault();
            setScrollable(true);
        };

        const mouseout = () => {
            setScrollable(false);
        };

        videoDiv.addEventListener('mouseover', mouseover);
        videoDiv.addEventListener('mouseout', mouseout);
    }, []);

    const handleTags = (array) => {
        const tags = array.data.map((d) => d.frame.tag);
        setTags(tags);
    };

    const handleSpeedUp = () => {
        if (playbackRate < 8.0) {
            setplaybackRate(playbackRate * 2.0);
        }
    };

    const handleSpeedDown = () => {
        if (playbackRate > 0.125) {
            setplaybackRate(playbackRate / 2.0);
        }
    };

    const handleSeekMouseDown = (): void => {
        setSeeking(true);
    };

    const handleSeekChange = (e) => {
        setPlayingState({ played: parseFloat(e.target.value) });
    };

    const handleSeekMouseUp = (e) => {
        setSeeking(false);
        player.current.seekTo(parseFloat(e.target.value));
    };

    const handleProgress = (state: any) => {
        setEndedVideo(false);
        setFrameNumber(Math.round(state.playedSeconds * parseInt(videoFps)));
        if (!seeking) {
            setPlayingState(state);
        }
    };

    const handleWheel = (e) => {
        const duration = player.current.getDuration();
        const currentTime = player.current.getCurrentTime();
        if (scrollable) {
            e.preventDefault();
            if (e.deltaY > 0) {
                player.current.seekTo(currentTime / duration - SCROLL_MOVE);
            } else if (e.deltaY < 0) {
                player.current.seekTo(currentTime / duration + SCROLL_MOVE);
            }
        }
    };

    const handleGoToFrame = (frameNumber: string, setFn = false) => {
        const playedSeconds = parseFloat(frameNumber) / parseInt(videoFps);
        const duration = player.current.getDuration();
        const played = playedSeconds / duration;
        if (played < 1) {
            player.current.seekTo(played);
            if (setFn) {
                setFrameNumber(parseFloat(frameNumber));
            }
        }
    };

    const handlePlay = () => {
        setPlaying(true);
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleStop = () => {
        setPlaying(false);
    };

    const handleEnded = async () => {
        if (isNotEmpty(countFiltered.data)) {
            countFiltered.end = true;
            countPostier.writeCount(
                token,
                [countFiltered],
                localStorage.getItem('filename'),
                localStorage.getItem('boxIp'),
                true,
                setVideos,
                setIsSaved,
            );
        } else {
            dataCount.end = true;
            countPostier.writeCount(
                token,
                [dataCount],
                localStorage.getItem('filename'),
                localStorage.getItem('boxIp'),
                true,
                setVideos,
                setIsSaved,
            );
        }

        await postier.getCounts(token, setVideosCount, boxIp);
        setPlaying(false);
        setEndedVideo(true);
        setShowImg(false);
        const videos = JSON.parse(localStorage.getItem('videos'));
        const currentVideoIdx = videos.findIndex(
            (video) => fileToJson(video.file) === localStorage.getItem('filename'),
        );
        if (currentVideoIdx >= 0 && currentVideoIdx < videos.length - 1) {
            const nextVideo = videos[currentVideoIdx + 1];
            setUrl(`http://${localStorage.getItem('boxIp')}:3000/api/video?video=${nextVideo.file}&token=${token}`);
            countPostier.getCount(
                token,
                fileToJson(nextVideo.file),
                setDataCount,
                setCategories,
                localStorage.getItem('boxIp'),
                setTags,
            );
            setVideoNameSelected(nextVideo.file);
            setFilename(fileToJson(nextVideo.file));
            localStorage.setItem('filename', fileToJson(nextVideo.file));
            setCptIn(nextVideo.globalCpt.cptIn);
            setCptOut(nextVideo.globalCpt.cptOut);
        }
    };

    return (
        <>
            <Videos />
            {glossary && <Glossary></Glossary>}
            <section id="section-video" className="section section-video">
                <div className="frame">
                    Frame
                    <input type="text" value={frameNumber} onChange={(e) => handleGoToFrame(e.target.value, true)} />
                </div>
                <div className="player-wrapper">
                    <ReactPlayer
                        ref={player}
                        playing={playing}
                        onPlay={handlePlay}
                        onEnded={handleEnded}
                        url={url}
                        width="100%"
                        height="100%"
                        playbackRate={playbackRate}
                        onProgress={handleProgress}
                        className="react-player"
                        onError={() => {
                            alert('Vidéo impossible à charger');
                            setVideoSelected(false);
                        }}
                    />
                </div>

                <div className="controls">
                    <img src={fastRewind} width="20" height="20" onClick={handleSpeedDown} />
                    <img src={stopButton} width="20" height="20" onClick={handleStop} />
                    <img src={playing ? play : pause} width="20" height="20" onClick={handlePlayPause} />
                    <img width="20" height="20" src={fastForward} onClick={handleSpeedUp} />
                    <span>x{playbackRate}</span>
                </div>

                <div className="tags">
                    <input
                        className="slider"
                        type="range"
                        min={0}
                        max={0.999999}
                        step="any"
                        value={playingState.played}
                        onMouseDown={handleSeekMouseDown}
                        onChange={handleSeekChange}
                        onMouseUp={handleSeekMouseUp}
                    />
                    <div className="container">
                        <svg width={width} height={height} className="tagger">
                            {tags.map((time: number, index: number) => (
                                <line
                                    key={index}
                                    x1={(time * width) / 100}
                                    y1={0}
                                    x2={(time * width) / 100}
                                    y2={30}
                                    stroke="black"
                                    strokeWidth="2"
                                />
                            ))}
                        </svg>
                        <progress className="progress" max={1} value={playingState.played} />
                    </div>
                </div>
            </section>
            <Counter playingState={playingState} />
        </>
    );
}
