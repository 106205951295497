import * as React from 'react';
import { render } from 'react-dom';
import { useContext, useEffect } from 'react';
import { postier } from './Postier';
import { countPostier } from '../../Count/Postier';
import { VideoContext } from '../../../Context/VideoContext';

import './video-list.scss';
import { fileToJson } from '../../../Utils/utils';
import { Video } from '../IVideos';

export default function Videos() {
    const videoContext = useContext(VideoContext);

    if (videoContext) {
        const {
            setTags,
            setCategories,
            videoNameSelected,
            setVideoNameSelected,
            setDataCount,
            setFinalCount,
            setFilename,
            setUrl,
            setVideoFps,
            setVideoSelected,
            token,
            boxIp,
            cptIn,
            cptOut,
            setCptIn,
            setCptOut,
            setVideosCount,
            videosCount,
            videos,
            setVideos,
            setShowImg,
        } = videoContext;

        const getCount = (video: Video) => {
            countPostier.getCount(token, fileToJson(video.file), setDataCount, setCategories, boxIp, setTags);
            setUrl(`http://${boxIp}:3000/api/video?video=${video.file}&token=${token}`);
            setVideoSelected(true);
            setFilename(video.file);
            localStorage.setItem('filename', fileToJson(video.file));
            setFinalCount({ data: [], totalCount: 0, date: null, end: false });
            setVideoFps(video.fps);
            setVideoNameSelected(video.file);
            setCptIn(video.globalCpt.cptIn);
            setCptOut(video.globalCpt.cptOut);
            setShowImg(false);
        };

        useEffect(() => {
            postier.getVideosList(token, setVideos, boxIp);
            postier.getCounts(token, setVideosCount, boxIp);
            setUrl('');
            setVideoSelected(false);
            setCategories([]);
        }, [boxIp]);
        
        return (
            <section className="section section-video-list">
                {videos.length !== 0 ? (
                    videos.map((video: Video) => (
                        <div
                            key={Math.random()}
                            id={video.file}
                            style={{
                                cursor: 'pointer',
                                background: videosCount.includes(fileToJson(video.file)) && '#C1E1C1',
                                color: 'black',
                            }}
                            className={videoNameSelected === video.file ? 'selected' : 'element'}
                            onClick={() => {
                                getCount(video);
                            }}
                        >
                            {video.file} :
                            <p>
                                IN :
                                {videoNameSelected === video.file
                                    ? cptIn + ' - OUT : ' + cptOut
                                    : video.globalCpt.cptIn + ' - OUT : ' + video.globalCpt.cptOut}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>Pas de vidéos</p>
                )}
            </section>
        );
    }

    return <h1>loading context ...</h1>;
}

render(<Videos />, document.querySelector('#app'));
