import axios from 'axios';
import 'regenerator-runtime/runtime';

const messages = {
    erreur: 'connexion impossible',
    input: 'le user ou le mot de passe est incorrect',
};

class Postier {
    async getToken(request: string, password: string, setToken, setUserId, setRole) {
        axios
            .post('auth/new', { key: request, password })
            .then((res) => {
                if (res.data.accessToken) {
                    setToken(res.data.accessToken);
                    setUserId(res.data.iduser);
                    setRole(res.data.role);
                    this.setWithExpiry('token', res.data.accessToken, 43200000);
                    this.setWithExpiry('userid', res.data.iduser, 43200000);
                    this.setWithExpiry('role', res.data.role, 43200000);
                } else {
                    alert(messages.input);
                }
            })
            .catch((err) => {
                alert(messages.erreur);
                console.log(err);
            });
    }

    private setWithExpiry(key: string, value: string | number, ttl: number) {
        const now = new Date();

        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        };

        localStorage.setItem(key, JSON.stringify(item));
    }
}

export const postier = new Postier();
