import * as React from 'react'
import { useContext } from 'react'
import {render} from 'react-dom'
import { AppProvider, VideoContext } from '../Context/VideoContext'
import Video from './Videos/Video'
import { Login } from './Login'
import { Admin } from './Admin'
import { PageSelector } from './PageSelector'
import { Header } from './Header'
import Glossary from './Glossary'


export default function App() {
    return (
        <AppProvider>
            <Content />
        </AppProvider>
    )
}


function Content() {
    const { token, page } = useContext(VideoContext);
    const admin = page === 'admin';
    const video = page === 'video';
    const glossary = page === 'glossary';
    return <>
        {token ?
            <div className="dashboard">
                <Header></Header>
                <PageSelector />
                {video ? <Video /> : null}
                {admin ? <Admin /> : null}
            </div>
            :
            <Login />
        }
    </>
}

render(<App/>,document.querySelector("#app"))