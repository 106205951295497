import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { VideoContext } from '../../Context/VideoContext';
import { countPostier } from './Postier';
import { isNotEmpty, countFilteredUtils, removeDuplicates } from '../../Utils/utils';
import { Count } from './Count.interface';
import { configPostier } from '../Admin/Postier';
import trash from '../../../assets/trash.svg';
import save from '../../../assets/save-logo.png';

import './style.scss';

enum Type {
    In = '01',
    Out = '10',
}

export function Counter({ playingState }) {
    const {
        saved,
        countFiltered,
        setCountFiltered,
        configCategories,
        setConfigCategories,
        dataConfig,
        setDataConfig,
        setCategories,
        categories,
        videoNameSelected,
        dataCount,
        setDataCount,
        filename,
        finalCount,
        setFinalCount,
        frameCaptured,
        videoSelected,
        setFrameNumber,
        setClicked,
        token,
        boxIp,
        cptIn,
        cptOut,
        setCptIn,
        setCptOut,
        setSaved,
        setVideos,
        showImg,
        setShowImg,
        setIsSaved,
    } = useContext(VideoContext);

    const [pressedKeys, setPressedKeys] = useState<string>('');
    const [countSended, setCountSended] = useState<boolean>(false);
    const [duplicates, setDuplicates] = useState<number>(0);
    const items = [];

    useEffect(() => {
        setCountSended(false);
    }, [countSended]);

    useEffect(() => {
        configPostier.getConfig(token, setDataConfig, setConfigCategories, boxIp, setSaved);
    }, [boxIp]);

    useEffect(() => {
        const cb = ({ key }) => {
            setPressedKeys(key);
        };
        document.addEventListener('keydown', cb);
        return () => {
            document.removeEventListener('keydown', cb);
        };
    }, []);

    useEffect(() => {
        dataConfig.data.map((dca) => {
            if (dca.shortcutIn === pressedKeys) {
                handleCount(Type.In, dca.category, dataCount, setDataCount);
            } else if (dca.shortcutOut === pressedKeys) {
                handleCount(Type.Out, dca.category, dataCount, setDataCount);
            }
        });
        setPressedKeys('');
    }, [pressedKeys, dataCount]);

    useEffect(() => {
        if (configCategories.length !== 0) {
            handleSetCategories(configCategories[0]);
        }
        setCountFiltered({ data: [], totalCount: 0, date: null, end: dataCount.end });
    }, [videoNameSelected]);

    const handleCount = (type: string, category: string, array: { data: Array<Count> }, setCount: any) => {
        setIsSaved(false);
        setShowImg(false);
        if (type === Type.In) {
            setCptIn(cptIn + 1);
        } else {
            setCptOut(cptOut + 1);
        }

        let duplicatesCopy = duplicates;
        const countFilteredUtilsVar = countFilteredUtils(array.data, frameCaptured, category, type);

        if (countFiltered.data.length !== 0 && dataCount.data.length !== 0) {
            duplicatesCopy = isNotEmpty(countFilteredUtilsVar)
                ? countFilteredUtilsVar[countFilteredUtilsVar.length - 1].frame.count + 1
                : 1;
            setDuplicates(duplicatesCopy);
        } else if (dataCount.data.length !== 0) {
            duplicatesCopy = isNotEmpty(countFilteredUtilsVar) ? countFilteredUtilsVar[0].frame.count + 1 : 1;
            setDuplicates(duplicatesCopy);
        } else {
            duplicatesCopy = countFilteredUtilsVar.length + 1;
            setDuplicates(duplicatesCopy);
        }

        array.data.push({
            frame: { number: frameCaptured, count: duplicatesCopy, tag: playingState.played * 100 },
            type,
            category,
        });

        const newFilter = {
            data: removeDuplicates(array.data),
            totalCount: array.data.length,
            date: new Date().toLocaleString(),
            end: dataCount.end,
        };

        setCount({
            data: array.data,
            totalCount: array.data.length,
            date: new Date().toLocaleString(),
            end: dataCount.end,
        });
        setCountFiltered(newFilter);

        if (array.data.length > 0 && array.data.length % saved === 0) {
            handleSendCount(newFilter, false);
            setShowImg(true);
        }
    };

    const handleSendCount = async (arr, fromSaved: boolean) => {
        await countPostier.writeCount(token, [arr], filename, boxIp, fromSaved, setVideos, setIsSaved);
        setCountSended(true);
    };

    const getCounter = (key: string, countArray: any) => {
        let cptIn = 0;
        let cptOut = 0;
        if (countArray.data !== undefined) {
            countArray.data.map((value) => {
                if (value.category === key && value.type === Type.In) {
                    cptIn += value.frame.count;
                } else if (value.category === key && value.type === Type.Out) {
                    cptOut += value.frame.count;
                }
            });
        }
        return {
            cptIn,
            cptOut,
        };
    };

    const removeItem = (
        arr: Array<Count>,
        frame: number,
        type: string,
        category: string,
        nbFrame: number,
        setCount,
    ) => {
        if (type === Type.In) {
            setCptIn(cptIn - nbFrame);
        } else {
            setCptOut(cptOut - nbFrame);
        }
        const arrayToRemove = arr.filter((e) => e.category === category && e.type === type && e.frame.number === frame);
        arr = arr.filter((val) => !arrayToRemove.includes(val));
        setCount({ data: arr, totalCount: arr.length, date: new Date().toLocaleString(), end: dataCount.end });
        setFinalCount({ data: arr, totalCount: arr.length, date: new Date().toLocaleString(), end: dataCount.end });
        setDataCount({ data: arr, totalCount: arr.length, date: new Date().toLocaleString(), end: dataCount.end });
    };

    const handleSetFNumber = (frameNumber) => {
        setClicked(true);
        setFrameNumber(frameNumber);
    };

    const handleSetCategories = (category: string) => {
        const copyCategories = [...categories];
        !copyCategories.includes(category) && copyCategories.push(category);
        setCategories(copyCategories);
    };

    useEffect(() => {
        const categoriesClassName: any = document.getElementsByClassName('select-category');
        for (let categories = 0; categories < categoriesClassName.length; categories++) {
            categoriesClassName[categories].style['display'] = 'block';
        }
    }, [videoNameSelected]);

    const removeCategory = (value: string) => {
        setCategories(categories.filter((item, index) => categories.indexOf(value) !== index));
    };

    for (const [index, value] of categories.entries()) {
        items.push(
            <div className="count">
                <p>
                    {value}
                    {dataCount.data.length === 0 && <button onClick={() => removeCategory(value)}>remove</button>}
                </p>
                <button
                    id={value}
                    className={Type.In}
                    onClick={(e: any) =>
                        isNotEmpty(dataCount.data)
                            ? handleCount(e.target.className, e.target.id, dataCount, setDataCount)
                            : handleCount(e.target.className, e.target.id, finalCount, setFinalCount)
                    }
                >
                    + In
                </button>
                <button
                    id={value}
                    className={Type.Out}
                    onClick={(e: any) =>
                        isNotEmpty(dataCount.data)
                            ? handleCount(e.target.className, e.target.id, dataCount, setDataCount)
                            : handleCount(e.target.className, e.target.id, finalCount, setFinalCount)
                    }
                >
                    + Out
                </button>
                <p>
                    Total IN :
                    {isNotEmpty(countFiltered.data)
                        ? getCounter(value, countFiltered).cptIn
                        : getCounter(value, dataCount).cptIn}
                </p>
                <ul>
                    {isNotEmpty(countFiltered.data)
                        ? countFiltered.data.map(
                              (t) =>
                                  t.type === Type.In &&
                                  t.category === value && (
                                      <div className={`${value}-${Type.In}`}>
                                          <li
                                              onClick={() => handleSetFNumber(t.frame.number)}
                                              style={{ float: 'left' }}
                                          >
                                              {'Frame #' + t.frame.number + ': ' + t.frame.count}
                                          </li>
                                          <img
                                              width="15"
                                              height="15"
                                              src={trash}
                                              onClick={() =>
                                                  removeItem(
                                                      countFiltered.data,
                                                      t.frame.number,
                                                      t.type,
                                                      t.category,
                                                      t.frame.count,
                                                      setCountFiltered,
                                                  )
                                              }
                                          />
                                      </div>
                                  ),
                          )
                        : dataCount.data.map(
                              (t) =>
                                  t.type === Type.In &&
                                  t.category === value && (
                                      <div className={`${value}-${Type.In}`}>
                                          <li
                                              onClick={() => handleSetFNumber(t.frame.number)}
                                              style={{ float: 'left' }}
                                          >
                                              {'Frame #' + t.frame.number + ': ' + t.frame.count}
                                          </li>
                                          <img
                                              width="15"
                                              height="15"
                                              src={trash}
                                              onClick={() =>
                                                  removeItem(
                                                      dataCount.data,
                                                      t.frame.number,
                                                      t.type,
                                                      t.category,
                                                      t.frame.count,
                                                      setDataCount,
                                                  )
                                              }
                                          />
                                      </div>
                                  ),
                          )}
                </ul>
                <p>
                    Total OUT :
                    {isNotEmpty(countFiltered.data)
                        ? getCounter(value, countFiltered).cptOut
                        : getCounter(value, dataCount).cptOut}
                </p>
                <ul>
                    {isNotEmpty(countFiltered.data)
                        ? countFiltered.data.map(
                              (t) =>
                                  t.type === Type.Out &&
                                  t.category === value && (
                                      <div className={`${value}-${Type.Out}`}>
                                          <li
                                              onClick={() => handleSetFNumber(t.frame.number)}
                                              style={{ float: 'left' }}
                                          >
                                              {'Frame #' + t.frame.number + ': ' + t.frame.count}
                                          </li>
                                          <img
                                              width="15"
                                              height="15"
                                              src={trash}
                                              onClick={() =>
                                                  removeItem(
                                                      countFiltered.data,
                                                      t.frame.number,
                                                      t.type,
                                                      t.category,
                                                      t.frame.count,
                                                      setCountFiltered,
                                                  )
                                              }
                                          />
                                      </div>
                                  ),
                          )
                        : dataCount.data.map(
                              (t) =>
                                  t.type === Type.Out &&
                                  t.category === value && (
                                      <div className={`${value}-${Type.Out}`}>
                                          <li
                                              onClick={() => handleSetFNumber(t.frame.number)}
                                              style={{ float: 'left' }}
                                          >
                                              {'Frame #' + t.frame.number + ': ' + t.frame.count}
                                          </li>
                                          <img
                                              width="15"
                                              height="15"
                                              src={trash}
                                              onClick={() =>
                                                  removeItem(
                                                      dataCount.data,
                                                      t.frame.number,
                                                      t.type,
                                                      t.category,
                                                      t.frame.count,
                                                      setDataCount,
                                                  )
                                              }
                                          />
                                      </div>
                                  ),
                          )}
                </ul>
            </div>,
        );
    }

    return (
        <section id="section-counter" className="section section-counter">
            {videoSelected && (
                <div>
                    <div className="count-save-container" style={{ textAlign: 'center' }}>
                        <button
                            className="save-count"
                            onClick={() =>
                                isNotEmpty(countFiltered.data)
                                    ? handleSendCount(countFiltered, true)
                                    : handleSendCount(dataCount, true)
                            }
                        >
                            Sauvegarder le comptage
                        </button>
                        <img
                            className="save-img"
                            style={{ visibility: showImg ? 'visible' : 'hidden' }}
                            src={save}
                            width="20"
                            height="20"
                        />
                    </div>
                    <div className="items">{items}</div>
                    {configCategories.map(
                        (category: string) =>
                            !categories.includes(category) && (
                                <p
                                    id={`select-${category}`}
                                    className="select-category"
                                    onClick={(e) => {
                                        handleSetCategories(category);
                                        e.target.style.display = 'none';
                                    }}
                                >
                                    {category}
                                </p>
                            ),
                    )}
                </div>
            )}
        </section>
    );
}
