import * as React from 'react';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { VideoContext } from '../../Context/VideoContext';
import { configPostier } from './Postier';
import trash from '../../../assets/trash.svg';

import './admin.scss';

export function Admin(): ReactElement {
    const { boxIp, dataConfig, setDataConfig, token, setSaved, configCategories, setConfigCategories } =
        useContext(VideoContext);

    const [category, setCategory] = useState<string>('');

    const items = [];

    useEffect(() => {
        configPostier.getConfig(token, setDataConfig, setConfigCategories, boxIp, setSaved);
    }, [boxIp]);

    const handleSetCategories = (): void => {
        const copyCategories = [...configCategories];
        if (!copyCategories.includes(category)) copyCategories.push(category);
        setConfigCategories(copyCategories);
    };

    const handleShortCut = (shortcutIn: string, shortcutOut: string, category: string): void => {
        const dc = dataConfig.data.find((element) => element.category === category);
        if (dc === undefined) {
            dataConfig.data.push({
                shortcutIn,
                shortcutOut,
                category,
            });
        } else {
            if (shortcutIn === null) {
                dc.shortcutOut = shortcutOut;
            } else {
                dc.shortcutIn = shortcutIn;
            }
        }

        setDataConfig({ data: dataConfig.data, savedNumber: dataConfig.savedNumber });
    };

    const handleSendConfig = async () => {
        await configPostier.writeConfig(token, dataConfig, boxIp);
    };

    const removeCategory = (value) => {
        setDataConfig({
            data: dataConfig.data.filter((el) => el.category !== value),
            savedNumber: dataConfig.savedNumber,
        });
        setConfigCategories(configCategories.filter((item, index) => configCategories.indexOf(value) !== index));
    };

    const handleSavedNumber = (e) => {
        setSaved(Number(e.target.value));
        setDataConfig({ data: dataConfig.data, savedNumber: e.target.value });
    };

    for (const [index, value] of configCategories.entries()) {
        items.push(
            <div key={index} className="shortcuts">
                <div>
                    {value}
                    <img className="trash" src={trash} width="15" height="15" onClick={() => removeCategory(value)} />
                </div>
                <div>
                    Raccourcis IN{' '}
                    <input
                        maxLength={1}
                        type="text"
                        defaultValue={dataConfig.data[index] !== undefined ? dataConfig.data[index].shortcutIn : ''}
                        value={dataConfig.data[index] !== undefined ? dataConfig.data[index].shortcutIn : ''}
                        className="01"
                        onChange={(e) => handleShortCut(e.target.value, null, value)}
                    />{' '}
                    OUT{' '}
                    <input
                        maxLength={1}
                        defaultValue={dataConfig.data[index] !== undefined ? dataConfig.data[index].shortcutOut : ''}
                        value={dataConfig.data[index] !== undefined ? dataConfig.data[index].shortcutOut : ''}
                        type="text"
                        className="10"
                        onChange={(e) => handleShortCut(null, e.target.value, value)}
                    />
                </div>
            </div>,
        );
    }

    return (
        <div className="dashboard-admin">
            <h1>Configuration paramètres</h1>
            <h2>Paramètres de sauvegarde</h2>
            <span>
                Sauvegarde automatique tous les
                <input
                    className="count-saved"
                    type="text"
                    defaultValue={dataConfig.savedNumber}
                    value={dataConfig.savedNumber}
                    onChange={handleSavedNumber}
                />{' '}
                comptages
            </span>
            <h2>Paramètres des catégories et des raccourcis</h2>
            <div>
                <label className="category-label">Nom de la nouvelle catégorie</label>
                <input type="text" onChange={(e) => setCategory(e.target.value)}></input>
                <button onClick={handleSetCategories}>Ajouter</button>
            </div>
            {items}
            <button onClick={handleSendConfig}>Sauvegarder la configuration</button>
        </div>
    );
}
