import axios from 'axios';
import 'regenerator-runtime/runtime';

class Postier {
    public async getVideosList(token: string, setVideos, ip: string) {
        await axios
            .get('/api-v0/videos', {
                params: { ip },
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                setVideos(res.data);
                localStorage.setItem('videos', JSON.stringify(res.data));
            })
            .catch((err) => console.log(err));
    }

    public async getCounts(token: string, setVideosCount, ip: string) {
        await axios
            .get('/api-v0/counts', {
                params: { ip },
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                setVideosCount(res.data);
            })
            .catch((err) => console.log(err));
    }
}

export const postier = new Postier();
